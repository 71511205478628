import http from "../http";
import store from "redux/store";
import axios from "axios";
import data from "../../../constants/results.json";
import moment from "moment";
const billingsEndPoints = {
  historyBillings: async (callback) => {
    const token = store.getState().session.token;
    let url = `${"" ||
      process.env.REACT_APP_API_URL}/factura/folios-por-facturar`;
    // url = `/apis.dbsystem/apithink/web/index.php/factura/folios-por-facturar`;
    // callback(null, data);
    // return;
    var settings = {
      url: url,
      method: "GET",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    console.log("response.data", response.data);
    if (response.data) {
      callback(null, response.data);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
  dataBillings: async () => {
    const token = store.getState().session.token;
    let url = `${"" || process.env.REACT_APP_API_URL}/factura/listado-facturas`;
    // url = `/factura/listado-facturas`;
    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        fecha_inicial: "2000-01-01",
        fecha_final: moment().format("YYYY-MM-DD"),
      }),
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    return response;
  },
  dataBillingsCities: async (id_estado) => {
    const token = store.getState().session.token;
    let url = `${"" || process.env.REACT_APP_API_URL}/datos-fiscales/ciudades`;
    // url = `/datos-fiscales/ciudades`;
    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        id_estado,
      }),
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    return response;
  },
  dataBillingsColonies: async (id_ciudad) => {
    const token = store.getState().session.token;
    let url = `${"" || process.env.REACT_APP_API_URL}/datos-fiscales/colonias`;
    // url = `/datos-fiscales/colonias`;
    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        id_ciudad,
      }),
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    return response;
  },
  dataBillingsPending: async () => {
    const token = store.getState().session.token;
    let url = `${"" ||
      process.env.REACT_APP_API_URL}/factura/folios-por-facturar`;
    // url = `/factura/folios-por-facturar`;
    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    return response;
  },
  saveDataBilling: async (body, callback) => {
    const token = store.getState().session.token;
    let url = `${"" ||
      process.env.REACT_APP_API_URL}/factura/guardar-datos-fiscales`;
    // url = `/apis.dbsystem/apithink/web/index.php/factura/guardar-datos-fiscales`;
    // callback(null, data);
    // return;

    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    if (response.status === "success") {
      callback(null, response);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
  saveBillingFolio: async (body, callback) => {
    const token = store.getState().session.token;
    let url = `${"" || process.env.REACT_APP_API_URL}/factura/por-folio`;

    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    if (response.status === "success") {
      callback(null, response);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
  saveBilling: async (body, callback) => {
    const token = store.getState().session.token;
    let url = `${"" || process.env.REACT_APP_API_URL}/factura/generar-factura`;
    // url = `/apis.dbsystem/apithink/web/index.php/factura/generar-factura`;
    // callback(null, data);
    // return;

    var settings = {
      url: url,
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    };
    console.log("settings", settings);
    if (!token) return;
    console.log("settings", settings);
    const response = await window.$.ajax(settings);
    console.log("reponse", response);
    if (response.status === "success") {
      callback(null, response);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
  getBillingPDF: async (folio, callback) => {
    const token = store.getState().session.token;
    console.log("tokessn", token);
    const url = `${process.env.REACT_APP_API_URL}/resultados/ver-pdf`;
    var settings = {
      url: url,
      method: "POST",
      // "mode": "no-cors",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
        Cookie:
          "advanced-apithink=u85tp50n3mjoeku98phkb2jnl4; _csrf-apithink=7b89e3bb7ed0e847c042ead425ffa17621eef7778655a349bfdb235307eeb89da%3A2%3A%7Bi%3A0%3Bs%3A14%3A%22_csrf-apithink%22%3Bi%3A1%3Bs%3A32%3A%22l9Hn3DknH4w80bS3YPOZz3X-KEsHcm2p%22%3B%7D",
      },
      data: JSON.stringify({ id_orden: folio }),
    };

    const response = await window.$.ajax(settings);
    console.log("reponses", response);
    if (`${response}`.startsWith("https://")) {
      console.log("repsonse", response);
      callback(null, response);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
  getPDF: async (folio, callback) => {
    const token = store.getState().session.token;
    console.log("tokessn", token);
    const url = `${process.env.REACT_APP_API_URL}/resultados/ver-pdf`;
    var settings = {
      url: url,
      method: "POST",
      // "mode": "no-cors",
      timeout: 0,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
        Cookie:
          "advanced-apithink=u85tp50n3mjoeku98phkb2jnl4; _csrf-apithink=7b89e3bb7ed0e847c042ead425ffa17621eef7778655a349bfdb235307eeb89da%3A2%3A%7Bi%3A0%3Bs%3A14%3A%22_csrf-apithink%22%3Bi%3A1%3Bs%3A32%3A%22l9Hn3DknH4w80bS3YPOZz3X-KEsHcm2p%22%3B%7D",
      },
      data: JSON.stringify({ id_orden: folio }),
    };

    const response = await window.$.ajax(settings);
    console.log("reponses", response);
    if (`${response}`.startsWith("https://")) {
      console.log("repsonse", response);
      callback(null, response);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
  getPDFFile: async (url, callback) => {
    var settings = {
      url: url,
      method: "GET",
    };

    const response = await window.$.ajax(settings);
    if (
      response.status &&
      response.status === "success" &&
      !!response.dbsystemurl
    ) {
      callback(null, response);
    } else {
      callback({ error: "No hay datos para mostrar" });
    }
  },
};

export default billingsEndPoints;
