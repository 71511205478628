import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  AccordionActions,
  Button,
  Grid,
  IconButton,
  Dialog,
  CircularProgress,
  Paper,
  DialogTitle,
  DialogContent,
  InputBase,
  DialogActions,
  TextField,
  DialogContentText,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import internalAPI from "../../../utils/internalAPI";
import {
  CloudDownloadOutlined,
  CropFreeOutlined,
  FileCopyOutlined,
} from "@material-ui/icons";
import ModalUI from "components/containers/ModalUI";
import styled from "styled-components";
import Input from "components/Delivery/subComponents/Input";
import repository from "../repository";
import Loading from "components/Loading";
import { Image } from "components/atoms";
import billingsEndPoints from "utils/internalAPI/endPoints/billingEndPoints";
import { useTheme } from "@material-ui/styles";
import Avisos from "components/Avisos";
import { QrReader } from "react-qr-reader";
import catalogos from "./catalogos.json";
import moment from "moment";
import { isString } from "lodash";
import QRExtractor from "./QRExtractor";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
const MaskIcon = styled("div")`
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  padding: 0px;
  background-color: red;
  background-size: "30px 30px";
  background-position: center;
  background-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${process.env.PUBLIC_URL + "icons/factura.svg"});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: "center";
`;

const FacturacionForm = ({ modeView = "list" }) => {
  const classes = useStyles();
  const [mode, setMode] = useState(null);
  const [modal, setModal] = useState(null);
  const [facturas, setFacturas] = useState({ facturadas: [], pendientes: [] });
  const defaultData = {
    rfc: { value: null },
    razon_social: {
      value: null,
    },
    estado: {
      value: null,
      options: catalogos.estados || [],
    },
    municipio: {
      value: null,
      options: catalogos.municipios || [],
    },
    colonia: { value: "", options: [] },
    calle: {
      value: null,
      options: [],
    },
    numero_exterior: {
      value: null,
      options: [],
    },
    numero_interior: {
      value: null,
      options: [],
    },
    email: {
      value: null,
    },
    cp: {
      value: null,
    },
    regimen_fiscal: {
      value: null,
      options: catalogos.regimenes_fiscales,
    },
    metodo_pago: {
      value: null,
      options: catalogos.formas_pago,
    },
    uso_cfdi: {
      value: null,
      options: catalogos.usos_cfdi,
    },
  };
  const [data, setData] = useState(defaultData);

  const [displayAviso, setdisplayAviso] = useState(false);
  const [scanner, setScanner] = useState(null);
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const results = useSelector((state) => state.studies.resultsGrouped);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const fields = [
    {
      key: "rfc",
      label: "RFC",
      end: (
        <IconButton onClick={() => setScanner("scan")}>
          <CropFreeOutlined />
        </IconButton>
      ),
    },
    { key: "razon_social", label: "Razón Social", type: "text" },
    { key: "estado", label: "Entidad Federativa", type: "options" },
    { key: "municipio", label: "Municipio", type: "options" },
    { key: "colonia", label: "Colonia", type: "options" },
    { key: "calle", label: "Calle", type: "text" },
    { key: "numero_exterior", label: "No. Ext.", type: "text" },
    { key: "numero_interior", label: "No. Int.", type: "text" },
    { key: "email", label: "Correo Electrónico", type: "text" },
    { key: "regimen_fiscal", label: "Régimen Fiscal", type: "options" },
    { key: "metodo_pago", label: "Método de Pago", type: "options" },
    { key: "uso_cfdi", label: "Uso de CFDI", type: "options" },
    // { key: "telefono", label: "Teléfono", type: "text" },
  ];

  // "rfc" : "XAXX010101000",
  // "razon_social" : "OSCAR JESUS HERNANDEZ ZELAYA",
  // "estado" : "SINALOA",
  // "municipio" : "CULIACAN",
  // "colonia" : "CENTRO",
  // "calle" : ".",
  // "numero_exterior" : "",
  // "numero_interior" : "",
  // "codigo_postal" : "80170",
  // "email" : "oscar.hernandez@gmail.com",
  // "regimen_fiscal" : "Sin obligaciones fiscales"
  const handleDownloadPDF = (id_orden) => {
    // return () => {
    internalAPI.getPDF(id_orden, (error, data) => {
      if (error || !data) {
        console.log("error", error);
        window.open();
        return;
      }
      // setLoading(true);
      // setSelected(data);
      handleDownloadPDFFile(data);
      window.open(data);
    });
    // };
  };
  const handleDownloadBilling = (item) => {
    // return () => {
    console.log("item", item);
    const url = (item.facturas[0] || {}).url_factura_pdf;
    if (url.includes("https://")) window.open(url);

    // };
  };
  const handleDownloadPDFFile = (url) => {
    // return () => {
    internalAPI.getPDFFile(url, (error, data) => {
      if (error || !data) {
        // window.open();
        return;
      }
      console.log("resltados", data);
      setSelected(data);
      // window.open(data);
    });
    // };
  };
  const getBillings = async () => {
    const response = await billingsEndPoints.dataBillings();
    const responsePending = await billingsEndPoints.dataBillingsPending();
    console.log("dataFacturas", response, {
      ...responsePending,
      facturadas: response[0]?.facturas || [],
      pendientes: responsePending?.folios || [],
    });
    setFacturas({
      ...responsePending,
      facturadas: response[0]?.facturas || [],
      pendientes: responsePending?.folios || [],
    });
  };

  useEffect(() => {
    getBillings();
  }, []);
  console.log("results", results);
  const getCiudades = async (id_estado) => {
    const response = await billingsEndPoints.dataBillingsCities(id_estado);

    setData({
      ...data,
      municipio: {
        value: null,
        options: response.map((e) => {
          return {
            value: e.id_ciudad,
            label: e.ciudad,
          };
        }),
      },
    });
  };
  const getColonias = async (id_ciudad) => {
    const response = await billingsEndPoints.dataBillingsColonies(id_ciudad);

    setData({
      ...data,
      colonia: {
        value: null,
        options: response.map((e) => {
          return {
            value: e.id_colonia,
            label: e.colonia,
          };
        }),
      },
    });
  };
  useEffect(() => {
    if (data.estado?.value && !data.dataSAT) getCiudades(data.estado?.value);
  }, [data.estado]);
  useEffect(() => {
    if (data.municipio?.value && !data.dataSAT)
      getColonias(data.municipio?.value);
  }, [data.municipio]);
  useEffect(() => {
    switch (mode) {
      case "scanner": {
        setScanner("scan");
        break;
      }
      default: {
      }
    }
  }, [mode]);
  const renderFields = (field, index) => {
    switch (field.type) {
      case "options": {
        const options = data[field.key]?.options || [];
        const value = data[field.key]?.value || "";

        return (
          <FormControl fullWidth>
            <InputLabel>{field.label}</InputLabel>
            <Select
              id={field.key}
              value={value}
              label={field.label}
              onChange={(e) => {
                setData({
                  ...data,
                  [field.key]: { value: e.target.value, options },
                });
              }}
            >
              {options.map((option, io) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        );
      }
      default: {
        return (
          <TextField
            id={field.key}
            fullWidth
            label={field.label}
            value={data[field.key]?.value || ""}
            placeholder={field.label}
            onChange={(e) =>
              setData({ ...data, [field.key]: { value: e.target.value } })
            }
            InputProps={{ endAdornment: field.end || undefined }}
          />
        );
      }
    }
  };

  return (
    <Box p={!isMd ? 1 : 0} display={modeView === "list" ? "block" : "none"}>
      <Box
        display={facturas.pendientes?.length === 0 ? "none" : undefined}
        p={4}
      >
        <Typography
          color="primary"
          variant={"h5"}
          style={{
            textAlign: "center",
            width: "100%",
            fontWeight: 800,
            marginBottom: 10,
          }}
        >
          {"Estudios por Facturar"}
        </Typography>
      </Box>
      <Box
        style={{
          borderRadius: 16,
          overflow: "hidden",
          boxShadow: "0px 0px 5px gray",
        }}
      >
        {/* {[...facturas.pendientes,{folio: 33332234, fecha:'2025-03-20'}] */}
        {facturas.pendientes
          .sort((a, b) => moment(b.fecha) - moment(a.fecha))
          .map((item, index) => (
            <Accordion
              key={index}
              // onChange={handleChange(index)}
              style={{ paddingRight: 0 }}
            >
              <AccordionSummary
                aria-controls={`${index}bh-content`}
                id={`${index}bh-header`}
              >
                <Grid container direction="row">
                  <Grid
                    xs
                    style={{ flexDirection: "column", alignSelf: "center" }}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        marginRight: 8,
                        width: "100%",
                        textAlign: "right",
                        fontWeight: 800,
                      }}
                    >
                      Folio: {item.folio}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs
                    alignContent="flex-start"
                    style={{ flexDirection: "column", alignSelf: "center" }}
                  >
                    <Typography
                      variant="caption"
                      align="left"
                      style={{ fontWeight: 600 }}
                    >
                      Fecha: {item.fecha}
                    </Typography>
                  </Grid>

                  <Grid
                    xs="auto"
                    style={{
                      flexDirection: "column",
                      alignSelf: "center",
                      padding: 5,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        const facturado = item.facturas?.facturado || false;
                        const facturas = item.facturas?.facturas || [];
                        if (facturado) {
                          handleDownloadBilling({
                            ...item,
                            facturas,
                            facturado,
                          });
                        } else {
                          setModal({ ...item, facturas, facturado });
                        }
                      }}
                    >
                      {"Facturar"}
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          ))}
      </Box>
      <Box p={4}>
        <Typography
          color="primary"
          variant={"h5"}
          style={{
            textAlign: "center",
            width: "100%",
            fontWeight: 800,
            marginBottom: 10,
          }}
        >
          {facturas.facturadas?.length === 0
            ? "No tienes estudios por facturar"
            : "Tus Facturas"}
        </Typography>
      </Box>
      <Box
        style={{
          borderRadius: 16,
          overflow: "hidden",
          boxShadow: "0px 0px 5px gray",
        }}
      >
        {facturas.facturadas
          .sort((a, b) => moment(b.fecha) - moment(a.fecha))
          .map((item, index) => (
            <Accordion
              key={index}
              // onChange={handleChange(index)}
              style={{ paddingRight: 0 }}
            >
              <AccordionSummary
                aria-controls={`${index}bh-content`}
                id={`${index}bh-header`}
              >
                <Grid container direction="row">
                  <Grid
                    xs
                    style={{ flexDirection: "column", alignSelf: "center" }}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        marginRight: 8,
                        width: "100%",
                        textAlign: "right",
                        fontWeight: 800,
                      }}
                    >
                      Factura: {item.no_factura}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs
                    alignContent="flex-start"
                    style={{ flexDirection: "column", alignSelf: "center" }}
                  >
                    <Typography
                      variant="caption"
                      align="left"
                      style={{ fontWeight: 600 }}
                    >
                      Fecha: {item.fecha}
                    </Typography>
                  </Grid>

                  <Grid
                    xs="auto"
                    style={{
                      flexDirection: "column",
                      alignSelf: "center",
                      padding: 5,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.url_pdf, "_blank");
                      }}
                    >
                      {"Ver"}
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          ))}
      </Box>
      <ModalUI
        open={!!selected}
        onClose={() => setSelected(null)}
        fullScreen={!isMd}
      >
        {loading && (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: "white" }}
          >
            <CircularProgress color={"primary"} />
            <Box>
              <Typography color={"primary"} style={{ marginTop: 8 }}>
                {"Cargando resultados"}
              </Typography>
              <Button
                fullWidth
                onClick={() => {
                  setSelected(null);
                  setLoading(false);
                }}
              >
                {"Cancelar"}
              </Button>
            </Box>
          </Box>
        )}
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: "white" }}
        />
      </ModalUI>
      <ModalUI
        open={!!modal}
        onClose={() => setModal(null)}
        transparent={true}
        fullScreen={!isMd}
      >
        <DialogTitle style={{ boxShadow: "0px 0px 3px gray", zIndex: 40000 }}>
          <Box width={"100%"}>
            <img
              className={classes.avatar}
              src="/images/logos/logo.svg"
              alt="deliabarraza"
              height={40}
            />
          </Box>
          {/* {'Facturación'} */}
        </DialogTitle>
        <DialogContent>
          {modal?.facturado ? (
            <Typography
              variant="h6"
              style={{ textAlign: "left", width: "100%", padding: 8 }}
            >
              {"Folio facturado: " + modal?.folio}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              style={{ textAlign: "left", width: "100%", padding: 8 }}
            >
              {"Facturar folio: " + modal?.folio}
            </Typography>
          )}
          {!(modal || {}).facturado && (
            <Grid container spacing={2} justifyContent="space-evenly">
              <Typography
                variant="h6"
                style={{ textAlign: "left", width: "100%", padding: 8 }}
              >
                {"Datos de Facturación"}
              </Typography>

              {fields.map((item, index) => {
                return (
                  <Grid item xs={12}>
                    {renderFields(item, index)}
                  </Grid>
                );
              })}
              {/* <Grid item xs={12}>
                  <FormControlLabel
                    fullWidth
                    control={<Checkbox name="jason" />}
                    label={
                      <div>
                        Acepto{" "}
                        <a
                          onClick={(e) => {
                            setdisplayAviso(true);
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          style={{
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                          }}
                        >
                          terminos y condiciones
                        </a>
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    fullWidth
                    control={<Checkbox name="jason" />}
                    label={
                      <div>
                        Acepto{" "}
                        <a
                          onClick={(e) => {
                            setdisplayAviso(true);
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          style={{
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                          }}
                        >
                          aviso de privacidad
                        </a>
                      </div>
                    }
                  />
                </Grid> */}
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ boxShadow: "0px 0px 8px gray", zIndex: 40000 }}>
          <Button
            onClick={() => {
              setModal(null);
              setData(defaultData);
              setMode(null);
            }}
            variant="contained"
            color="primary"
          >
            {"Cancelar"}
          </Button>
          {console.log("data------>", data)}
          {(modal || {}).facturado ? (
            <Button variant="outlined" disabled={!data.rfc}>
              {"Ver"}
            </Button>
          ) : (
            <Button
              onClick={() => {
                console.log("values", data);
                // if (data.referencia) {
                //   billingsEndPoints.saveDataBillings(
                //     { folios: [data.referencia] },
                //     (err, res) => {
                //       console.log("res", res);
                //       console.log("err", err);
                //       if (res) {
                //         billingsEndPoints.saveBillings(data, (err, res) => {
                //           console.log("res", res);
                //           console.log("err", err);
                //           res && setData(res.datos_fiscales);
                //         });
                //       }
                //     }
                //   );
                // } else {
                //   billingsEndPoints.saveBillings(data, (err, res) => {
                //     console.log("res", res);
                //     console.log("err", err);
                //     res && setData(res.datos_fiscales);
                //   });
                // }
                let body = {};
                console.log("modal", modal?.folio);
                if (data.dataSAT) {
                  body = {
                    id_uso_cfdi: data.uso_cfdi?.value,
                    id_forma_pago: data.metodo_pago?.value,
                    folios: [modal?.folio],
                    datos_fiscales_sat: {
                      rfc: data.rfc?.value,
                      razon_social: data.razon_social?.value,
                      estado: data.estado?.value,
                      municipio: data.municipio?.value,
                      colonia: data.colonia?.value,
                      calle: data.calle?.value,
                      numero_exterior: data.numero_exterior?.value,
                      numero_interior: data.numero_interior?.value,
                      codigo_postal: data.cp?.value,
                      email: data.email?.value,
                      regimen_fiscal: data.regimen_fiscal?.value,
                    },
                  };
                } else {
                  body = {
                    id_uso_cfdi: data.uso_cfdi?.value,
                    id_forma_pago: data.metodo_pago?.value,
                    folios: [modal?.folio],
                    datos_fiscales_sat: {
                      rfc: data.rfc?.value,
                      razon_social: data.razon_social?.value,
                      estado: data.estado?.options?.find(
                        (e) => e.value === data.estado?.value
                      )?.label,
                      id_estado: data.estado?.value,
                      municipio: data.municipio?.options?.find(
                        (e) => e.value === data.municipio?.value
                      )?.label,
                      id_ciudad: data.municipio?.value,
                      colonia: data.colonia?.options?.find(
                        (e) => e.value === data.colonia?.value
                      )?.label,
                      id_colonia: data.colonia?.value,
                      calle: data.calle?.value,
                      numero_exterior: data.numero_exterior?.value,
                      numero_interior: data.numero_interior?.value,
                      codigo_postal: data.cp?.value,
                      email: data.email?.value,
                      regimen_fiscal: data.regimen_fiscal?.options?.find(
                        (e) => e.value === data.regimen_fiscal?.value
                      )?.label,
                      id_regimen_fiscal: data.regimen_fiscal?.value,
                    },
                  };
                }
                console.log("body", body);

                internalAPI.saveBillingFolio(body, (error, data) => {
                  if (error || !data) {
                    console.log("error", error);
                    setData({ ...data, error: error });
                    return;
                  }
                  console.log("data", data);

                  setModal(null);
                });
              }}
              variant="outlined"
              disabled={
                !data.rfc?.value ||
                !data.email?.value ||
                !data.razon_social?.value ||
                !data.metodo_pago?.value ||
                !data.uso_cfdi?.value ||
                !data.regimen_fiscal?.value
              }
            >
              {"Facturar"}
            </Button>
          )}
        </DialogActions>
      </ModalUI>
      <ModalUI
        open={scanner === "scan"}
        onClose={() => setScanner(null)}
        fullScreen={!isMd}
      >
        <DialogTitle style={{ boxShadow: "0px 0px 8px gray", zIndex: 40000 }}>
          <Box width={"100%"}>
            <img
              className={classes.avatar}
              src="/images/logos/logo.svg"
              alt="deliabarraza"
              height={40}
            />
          </Box>
          {"Captura el código QR de tu cédula de identificación fiscal"}
        </DialogTitle>
        <DialogContent>
          <QrReader
            delay={500}
            onResult={(scan) => {
              if (
                `${scan}`.includes(
                  "https://siat.sat.gob.mx/app/qr/faces/pages/mobile"
                )
              ) {
                setScanner("loading");
                repository.getRFCData(scan, (dataSAT) => {
                  console.log("data", dataSAT);
                  const editData = {
                    rfc: { value: `${scan}`.split("_")[1], options: [] },
                    razon_social: {
                      value:
                        dataSAT["Denominación o Razón Social"] ||
                        `${dataSAT.Nombre} ${dataSAT["Apellido Paterno"]} ${dataSAT["Apellido Materno"]}`,
                      options: [],
                    },
                    estado: {
                      value: `${dataSAT["Entidad Federativa"]}`,
                      options: [
                        {
                          value: `${dataSAT["Entidad Federativa"]}`,
                          label: `${dataSAT["Entidad Federativa"]}`,
                        },
                      ],
                    },
                    municipio: {
                      value: `${dataSAT["Municipio o delegación"]}`,
                      options: [
                        {
                          value: `${dataSAT["Municipio o delegación"]}`,
                          label: `${dataSAT["Municipio o delegación"]}`,
                        },
                      ],
                    },
                    colonia: {
                      value: `${dataSAT["Colonia"]}`,
                      options: [
                        {
                          value: `${dataSAT["Colonia"]}`,
                          label: `${dataSAT["Colonia"]}`,
                        },
                      ],
                    },
                    calle: {
                      value: `${dataSAT["Nombre de la vialidad"]}`,
                      options: [],
                    },
                    numero_exterior: {
                      value: `${dataSAT["Número exterior"]}`,
                      options: [],
                    },
                    numero_interior: {
                      value: `${dataSAT["Número interior"]}`,
                      options: [],
                    },
                    email: {
                      value: `${dataSAT["Correo electrónico"]}`,
                      options: [],
                    },
                    cp: { value: `${dataSAT["CP"]}`, options: [] },
                    regimen_fiscal: {
                      value: isString(dataSAT["Régimen"])
                        ? [
                            {
                              value: dataSAT["Régimen"],
                              label: dataSAT["Régimen"],
                            },
                          ]
                        : (dataSAT["Régimen"] || [])?.length === 1
                        ? dataSAT["Régimen"][0]
                        : null,
                      options: isString(dataSAT["Régimen"])
                        ? [
                            {
                              value: dataSAT["Régimen"],
                              label: dataSAT["Régimen"],
                            },
                          ]
                        : (dataSAT["Régimen"] || []).map((e) => {
                            return {
                              value: e,
                              label: e,
                            };
                          }),
                    },
                    metodo_pago: {
                      value: null,
                      options: catalogos.formas_pago,
                    },
                    uso_cfdi: {
                      value: null,
                      options: catalogos.usos_cfdi,
                    },
                    dataSAT: true,
                  };
                  setData({ ...data, ...editData });
                  setScanner(null);
                });
              }
            }}
            // onScan={(scan) => {
            //   if (
            //     `${scan}`.includes(
            //       "https://siat.sat.gob.mx/app/qr/faces/pages/mobile"
            //     )
            //   ) {
            //     setScanner("loading");
            //     repository.getRFCData(scan, (dataSAT) => {
            //       console.log("data", dataSAT);
            //       const editData = {
            //         rfc: `${scan}`.split("_")[1],
            //         cliente: `${dataSAT.Nombre} ${dataSAT["Apellido Paterno"]} ${dataSAT["Apellido Materno"]}`,
            //         email: `${dataSAT["Correo electrónico"]}`,
            //         colonia: `${dataSAT["Colonia"]}`,
            //         calle: `${dataSAT["Nombre de la vialidad"]}`,
            //         numero_exterior: `${dataSAT["Número exterior"]}`,
            //         numero_interior: `${dataSAT["Número interior"]}`,
            //         estado: `${dataSAT["Entidad Federativa"]}`,
            //         municipio: `${dataSAT["Municipio o delegación"]}`,
            //         cp: `${dataSAT["CP"]}`,
            //       };

            //       setData({ ...data, ...editData });
            //       setScanner(null);
            //     });
            //   }
            // }}
          />
        </DialogContent>
        <DialogActions style={{ boxShadow: "0px 0px 8px gray", zIndex: 40000 }}>
          <Button
            onClick={() => {
              setScanner(null);
              setData(defaultData);
              setMode(null);
            }}
            variant="contained"
            color="primary"
          >
            {"Cancelar"}
          </Button>
        </DialogActions>
      </ModalUI>
      {scanner === "loading" && <Loading />}
      <ModalUI open={displayAviso} onClose={() => setdisplayAviso(false)}>
        <Avisos />
        <DialogActions>
          <Button onClick={() => setdisplayAviso(false)}>{"Salir"}</Button>
        </DialogActions>
      </ModalUI>
      <Dialog open={!mode && !!modal} onClose={() => setMode("write")}>
        <DialogTitle>{"Facturación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"¿Como deseas ingresar los datos de facturación?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModal(null)}
          >
            {"Cancelar"}
          </Button>
          {/* <QRExtractor label={'Subir Cedula'} onClick={() => setMode("file")} /> */}
          <Button onClick={() => setMode("scanner")}>{"Leer QR"}</Button>
          <Button onClick={() => setMode("write")}>
            {"Capturar Manualmente"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FacturacionForm;
