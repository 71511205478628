import React, { useState, useRef, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist";
import jsQR from "jsqr";
import { Button } from "@material-ui/core";

// Configurar la ruta del worker manualmente
pdfjsLib.GlobalWorkerOptions.workerSrc = `${window.location.origin}/pdf.worker.min.js`;

const QRExtractor = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPdfUrl(url);
    }
  };

  const readQRCodeFromPdf = async () => {
    if (!pdfUrl) return;

    try {
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      const page = await pdf.getPage(1);

      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport }).promise;

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, canvas.width, canvas.height);

      setQrCode(code ? code.data : "No QR Code found");
    } catch (error) {
      console.error("Error procesando el PDF:", error);
      setQrCode("Error al leer el PDF");
    }
  };

  useEffect(() => {
    if (pdfUrl) readQRCodeFromPdf();
  }, [pdfUrl]);

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <Button onClick={() => fileInputRef.current.click()}>Subir Cédula</Button>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {qrCode && <p>QR Code: {qrCode}</p>}
    </div>
  );
};

export default QRExtractor;
